import {
  Button,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import firebase from "firebase";
import _ from "lodash";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import AlertDialog from "../../../../components/alerts/AlertDialog";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import CardCommon from "../../../../components/card/CardCommon";
import { fetchAllLocations } from "../../../../services/locationApp/locationService";
import { handleChangeRestaurant } from "../../../../utils";
import {
  APP_PRESTO_CUSTOMER,
  APP_PRESTO_EATPRESTO,
  APP_PRESTO_INSIGHT,
  APP_PRESTO_INVENTORY,
  APP_PRESTO_LOCATION,
  APP_PRESTO_MENU,
  APP_PRESTO_SALES,
  APP_PRESTO_TASK,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
} from "../../../../utils/consts";
import { removeAllCookies } from "../../../../utils/cookies";
import commonThemeDark from "../../../../root/theme/dark";
import StorefrontIcon from "@material-ui/icons/Storefront";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import eatprestoLogo from "../../../../assets/images/eatPrestoLogo.png";
import { handleAppVisible } from "../../../../utils/appVisibility";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "80px",
    [theme.breakpoints.up("md")]: {
      height: "100vh",
      marginTop: "0px",
    },
  },
  iconButton: {
    width: "152px",
    height: "152px",
    [theme.breakpoints.up("sm")]: {
      height: "220px",
      width: "220px",
    },
  },
  gridStyle: {
    width: "100%",
    padding: "4px",
    [theme.breakpoints.up("sm")]: {
      padding: "16px",
    },
  },
}));

const APPS = [
  {
    title: "MANAGE LOCATIONS",
    appUrlParam: "presto-locations",
    icon: <StorefrontIcon fontSize="inherit" />,
    backgroundColor: commonThemeDark.palette.custom.yellow.main,
    appName: APP_PRESTO_LOCATION,
  },
  {
    title: "ONLINE ORDERING",
    appUrlParam: "eatpresto",
    icon: (
      <img
        src={eatprestoLogo}
        width="100px"
        height="60px"
        alt="eatpresto logo"
      />
    ),
    backgroundColor: commonThemeDark.palette.custom.red.light,
    appName: APP_PRESTO_EATPRESTO,
  },
  {
    title: "SALES AND REPORTING",
    appUrlParam: "presto-sales",
    icon: <SignalCellularAltIcon fontSize="inherit" />,
    backgroundColor: "#25ACE3",
    appName: APP_PRESTO_SALES,
  },
  // {
  //   title: "PRESTO TASKS",
  //   appUrlParam: "presto-task",
  //   icon: <PlaylistAddCheckIcon style={{ fontSize: "60px" }} />,
  //   backgroundColor: commonThemeDark.palette.custom.green.light,
  //   appName: APP_PRESTO_TASK,
  // },
  // {
  //   title: "Menus",
  //   appUrlParam: "presto-menus",
  //   icon: <MenuBookIcon style={{ fontSize: "60px" }} />,
  //   backgroundColor: commonThemeDark.palette.custom.purple.main,
  //   appName: APP_PRESTO_MENU,
  // },
  {
    title: "Inventory",
    appUrlParam: "presto-inventory",
    icon: <BusinessCenterIcon style={{ fontSize: "60px" }} />,
    backgroundColor: commonThemeDark.palette.custom.green.light,
    appName: APP_PRESTO_INVENTORY,
  },
  {
    title: " Customers & Loyalty",
    appUrlParam: "presto-customer",
    icon: <span className="material-symbols-outlined" style={{ fontSize: "60px" }}>
    group
    </span>,
    backgroundColor: "#F7931E",
    appName: APP_PRESTO_CUSTOMER,
  },
  {
    title: " Insight",
    appUrlParam: "presto-insight",
    icon: <span className="material-symbols-outlined" style={{ fontSize: "60px" }}>
    group
    </span>,
    backgroundColor: "#F7931E",
    appName: APP_PRESTO_INSIGHT,
  },
   
];

interface PrestoAppObject {
  title: string;
  appUrlParam: string;
}

export interface ManageAppsProps {
  open: boolean;
  setOpen: boolean;
}

/* Get all location information using API call at initial load. */
const ManageApps: React.FunctionComponent<ManageAppsProps> = () => {
  const [appList, setAppList] = useState<Array<PrestoAppObject>>([]);
  const [firstLocation, setFirstLocation] = useState<any>({});
  const [open, setOpen] = useState<boolean>(false);
  const [error, setError] = useState("");
  const [authUser, setAuthUser] = useState<{
    isAuthenticated: boolean;
    name: string | "";
    email: string | "";
    uId: String | "";
  }>({
    isAuthenticated: false,
    name: "",
    email: "",
    uId: "",
  });

  /* Set all apps details to appList state */
  const fetchPrestoApps = useCallback(async () => {
    try {
      setAppList(APPS);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, []);

  /* Get all location information using API call and response data set to firstLocation state. */
  const fetchLocations = useCallback(async () => {
    try {
      const res = await fetchAllLocations();
      const firstLocation = res.data.data[0];
      setFirstLocation(firstLocation);
    } catch (err) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  }, []);

  useEffect(() => {
    fetchLocations();
    fetchPrestoApps();
  }, [fetchLocations, fetchPrestoApps]);

  useEffect(() => {
    const CURRENT_DOMAIN =
      process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN || "localhost";

    // Checking if the user is now in the account application.
    const isAccountApp = window.location.href.includes(CURRENT_DOMAIN);

    if (isAccountApp) {
      firebase.auth().onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          firebaseUser
            .getIdToken(true)
            .then(function (idToken) {
              // Get the user details
              setAuthUser({
                isAuthenticated: true,
                email: firebaseUser?.email || "",
                name: firebaseUser?.displayName || "",
                uId: firebaseUser?.uid || "",
              });
            })
            .catch(function (error) {
              alert("error");
              setAuthUser({
                isAuthenticated: false,
                email: "",
                name: "",
                uId: "",
              });
            });
        } else {
          setAuthUser({ isAuthenticated: false, email: "", name: "", uId: "" });
        }
      });
    }
  }, []);

  /* Navigate the selected app. */
  const handleNotOpenNewTab = (appUrlParam: any) => {
    if (_.isEmpty(firstLocation)) {
      return setOpen(true);
    }
    setOpen(false);
    // Store location data in the local storage.
    handleChangeRestaurant(firstLocation);
    if (appUrlParam === "eatpresto") {
      window.location.href = `${process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN}`;
    } else if (appUrlParam === "presto-locations") {
      window.location.href = `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}`;
    } else if (appUrlParam === "presto-sales") {
      window.location.href = `${process.env.REACT_APP_PRESTO_SALES_DOMAIN}`;
    } else if (appUrlParam === "presto-task") {
      window.location.href = `${process.env.REACT_APP_PRESTO_TASK_DOMAIN}`;
    } else if (appUrlParam === "presto-menus") {
      window.location.href = `${process.env.REACT_APP_PRESTO_MENU_DOMAIN}`;
    } else if (appUrlParam === "presto-inventory") {
      window.location.href = `${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}`;
    } else if (appUrlParam === "presto-customer") {
      window.location.href = `${process.env.REACT_APP_PRESTO_CUSTOMER_DOMAIN}`;
    }
  };

  const handleOpenNewTab = (appUrlParam: any) => {
    if (_.isEmpty(firstLocation)) {
      return setOpen(true);
    }
    setOpen(false);
    // Store location data in the local storage.
    handleChangeRestaurant(firstLocation);
    if (appUrlParam === "eatpresto") {
      window.open(`${process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN}`, "_blank");
    } else if (appUrlParam === "presto-locations") {
      window.open(`${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}`, "_blank");
    } else if (appUrlParam === "presto-sales") {
      window.open(`${process.env.REACT_APP_PRESTO_SALES_DOMAIN}`, "_blank");
    } else if (appUrlParam === "presto-task") {
      window.open(`${process.env.REACT_APP_PRESTO_TASK_DOMAIN}`, "_blank");
    } else if (appUrlParam === "presto-menus") {
      window.open(`${process.env.REACT_APP_PRESTO_MENU_DOMAIN}`, "_blank");
    } else if (appUrlParam === "presto-inventory") {
      window.open(`${process.env.REACT_APP_PRESTO_INVENTORY_DOMAIN}`, "_blank");
    } else if (appUrlParam === "presto-customer") {
      window.open(`${process.env.REACT_APP_PRESTO_CUSTOMER_DOMAIN}`, "_blank");
    }
  };

  const handleButtonClick = (event: any, appUrlParam: any) => {
    // Check if Ctrl key is pressed
    if ((event.ctrlKey && event.button === 0) || event.button === 1) {

      return handleOpenNewTab(appUrlParam);
    } else {
      return handleNotOpenNewTab(appUrlParam);
    }
  };

  /* Log out web site */
  const handleLogout = () => {
    setOpen(false);
    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.href = `${process.env.REACT_APP_PRESTO_ACCOUNT_DOMAIN}/accounts/sign-in`;
        removeAllCookies();
      })
      .catch(() => {
        setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      });
  };

  const matchesLargeScreen = useMediaQuery("(max-width: 960px)");
  const matchesSmallScreen = useMediaQuery("(max-width: 600px)");

  /* Change the app width using the screen size */
  const style = () => {
    // screenSize < 960 and screenSize > 600
    if (matchesLargeScreen && !matchesSmallScreen) {
      return {
        display: "flex",
        justifyContent: "center",
        width: "504px",
      };
    } else if (matchesLargeScreen && matchesSmallScreen) {
      // screenSize < 960 and screenSize < 600
      return {
        display: "flex",
        justifyContent: "center",
        width: "340px",
      };
    } else {
      // screenSize > 960 and screenSize > 600
      return {
        display: "flex",
        justifyContent: "center",
        width: "756px",
      };
    }
  };

  const classes = useStyles();

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              placeItems: "center",
              alignItems: "center",
            }}
          >
            <Grid container style={style()}>
              {appList.map(
                (app: any) =>
                  handleAppVisible(app.appName, authUser.uId) && (
                    <Grid item xs={6} md={4} className={classes.gridStyle}>
                      <CardCommon backgroundColor={"entity_background"}>
                        <Button
                          className={classes.iconButton}
                          onClick={(event) =>
                            handleButtonClick(event, app.appUrlParam)
                          }
                          onMouseDown={(event) =>
                            handleButtonClick(event, app.appUrlParam)
                          }
                        >
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item xs={12}>
                              <Typography
                                variant="h2"
                                style={{ color: app.backgroundColor }}
                              >
                                {app.icon}
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="body1" align="center">
                                {app.title}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Button>
                      </CardCommon>
                    </Grid>
                  ),
              )}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <AlertDialog
        open={open}
        confirmAction={handleLogout}
        title="Error"
        desc="You don't have permission."
        severity="error"
        confirmLabel="Log out"
        disableBackdropClick
      />

      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default ManageApps;
