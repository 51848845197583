import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import CardCommon from "../../../../components/card/CardCommon";

export interface BoxDetailsProps {
  keyProps: any;
  value: any;
}

const convertSeconds = (seconds: any) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}m ${remainingSeconds}s`;
};

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.background.entity_background,
      borderRadius: 10,
      padding: "0px 20px",
    },
  }),
);

const TITLES: any = {
  deliveryAverageTime: "Delivery Average Time",
  loadAverageTime: "Load Average Time",
  locationAverageTime: "Location Average Time",
  preparationAverageTime: "Preparation Average Time",
  stationMetrics: "Station Metrics",
};

const BoxDetails: React.FunctionComponent<BoxDetailsProps> = ({
  keyProps,
  value,
}) => {
  const classes = useStyles();
  const showMetrics =
    keyProps !== "deliveryAverageTime" &&
    keyProps !== "stationMetrics" &&
    keyProps !== "locationAverageTime";
  return (
    <Box className={classes.root}>
      <Typography variant="h4" align="left" style={{ paddingTop: 20 }}>
        {TITLES[keyProps] || keyProps}
      </Typography>

      {showMetrics && (
        <Grid container spacing={2}>
          {["Count", "Time"].map((label, index) => (
            <React.Fragment key={label}>
              <Grid item xs={6} style={{ display: "flex" }}>
                <Typography variant="body1" style={{ paddingTop: 20 }}>
                  {label}
                </Typography>
                <Typography variant="body1" style={{ paddingTop: 20 }}>
                  {": "}
                </Typography>
                <Typography
                  variant="body1"
                  style={{ paddingTop: 20, paddingLeft: "12px" }}
                >
                  {value[index === 0 ? "count" : "totalTime"]}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      )}

      {keyProps === "deliveryAverageTime" ? (
        <Grid container spacing={2}>
          {["Count", "Time"].map((label, index) => (
            <React.Fragment key={label}>
              <Grid item xs={6} style={{ display: "flex" }}>
                <Typography
                  variant="body1"
                  align="left"
                  style={{ paddingTop: 20 }}
                >
                  {label} :
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  style={{ paddingTop: 20, paddingLeft: "12px" }}
                >
                  {index === 0
                    ? value?.total
                      ? value?.total?.["count"]
                      : 0
                    : convertSeconds(
                        value?.total ? value?.total?.["totalTime"] : 0,
                      )}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}

          {value?.drivers && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                style={{ paddingTop: 8, paddingBottom: 8, fontWeight: "bold" }}
              >
                Drivers
              </Typography>
            </Grid>
          )}
          {value?.drivers && (
            <CardCommon
              backgroundColor="table_header_background"
              style={{ margin: "0px 0px", height: 40 }}
            >
              <Grid container>
                {["Name", "Count", "Time"].map((label, index) => (
                  <Grid item xs={4} key={label} style={{ paddingTop: "8px" }}>
                    <Typography
                      align={
                        index === 0 ? "left" : index === 1 ? "center" : "right"
                      }
                      style={{
                        paddingLeft: index === 0 ? "12px" : "0px",
                        paddingRight: index === 2 ? "12px" : "0px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardCommon>
          )}
          <Grid container style={{ paddingBottom: "12px" }}>
            {Object.entries(value?.drivers || {}).map(([key, val]: any) => (
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{ margin: "4px 0px", height: 40 }}
              >
                <Grid container key={key}>
                  {[key, val.count, val.totalTime].map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Typography
                        style={{
                          paddingTop: "8px",
                          paddingLeft: index === 0 ? "12px" : "0px",
                          paddingRight: index === 2 ? "12px" : "0px",
                        }}
                        align={
                          index === 0
                            ? "left"
                            : index === 1
                            ? "center"
                            : "right"
                        }
                      >
                        {index === 2 ? convertSeconds(item) : item}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardCommon>
            ))}
          </Grid>
        </Grid>
      ) : keyProps === "locationAverageTime" ? (
        <Grid container spacing={2}>
          {console.log("ddddddddddddddfgdfgdf", value)}
          {["Count", "Time"].map((label, index) => (
            <React.Fragment key={label}>
              <Grid item xs={6} style={{ display: "flex" }}>
                <Typography
                  variant="body1"
                  align="left"
                  style={{ paddingTop: 20 }}
                >
                  {label} :
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  style={{ paddingTop: 20, paddingLeft: "12px" }}
                >

                  {index === 0
                    ? value?.total
                      ? value?.total?.["count"]
                      : 0
                    : convertSeconds(
                        value?.total ? value?.total?.["totalTime"] : 0,
                      )}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
          {value?.channel && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                style={{ paddingTop: 8, paddingBottom: 8, fontWeight: "bold" }}
              >
                Channel
              </Typography>
            </Grid>
          )}
          {value?.channel && (
            <CardCommon
              backgroundColor="table_header_background"
              style={{ margin: "0px 0px", height: 40 }}
            >
              <Grid container>
                {["Name", "Count", "Time"].map((label, index) => (
                  <Grid item xs={4} key={label} style={{ paddingTop: "8px" }}>
                    <Typography
                      align={
                        index === 0 ? "left" : index === 1 ? "center" : "right"
                      }
                      style={{
                        paddingLeft: index === 0 ? "12px" : "0px",
                        paddingRight: index === 2 ? "12px" : "0px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardCommon>
          )}
          <Grid container style={{ paddingBottom: "12px" }}>
            {Object.entries(value?.channel || {}).map(([key, val]: any) => (
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{ margin: "4px 0px", height: 40 }}
              >
                <Grid container key={key}>
                  {[key, val.count, val.totalTime].map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Typography
                        style={{
                          paddingTop: "8px",
                          paddingLeft: index === 0 ? "12px" : "0px",
                          paddingRight: index === 2 ? "12px" : "0px",
                        }}
                        align={
                          index === 0
                            ? "left"
                            : index === 1
                            ? "center"
                            : "right"
                        }
                      >
                        {index === 2 ? convertSeconds(item) : item}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardCommon>
            ))}
          </Grid>

          {value?.platform && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                style={{ paddingTop: 8, paddingBottom: 8, fontWeight: "bold" }}
              >
                Platform
              </Typography>
            </Grid>
          )}
          {value?.platform && (
            <CardCommon
              backgroundColor="table_header_background"
              style={{ margin: "0px 0px", height: 40 }}
            >
              <Grid container>
                {["Name", "Count", "Time"].map((label, index) => (
                  <Grid item xs={4} key={label} style={{ paddingTop: "8px" }}>
                    <Typography
                      align={
                        index === 0 ? "left" : index === 1 ? "center" : "right"
                      }
                      style={{
                        paddingLeft: index === 0 ? "12px" : "0px",
                        paddingRight: index === 2 ? "12px" : "0px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardCommon>
          )}
          <Grid container style={{ paddingBottom: "12px" }}>
            {Object.entries(value?.platform || {}).map(([key, val]: any) => (
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{ margin: "4px 0px", height: 40 }}
              >
                <Grid container key={key}>
                  {[key, val.count, val.totalTime].map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Typography
                        style={{
                          paddingTop: "8px",
                          paddingLeft: index === 0 ? "12px" : "0px",
                          paddingRight: index === 2 ? "12px" : "0px",
                        }}
                        align={
                          index === 0
                            ? "left"
                            : index === 1
                            ? "center"
                            : "right"
                        }
                      >
                        {index === 2 ? convertSeconds(item) : item}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardCommon>
            ))}
          </Grid>

          {value?.mode && (
            <Grid item xs={12}>
              <Typography
                variant="h6"
                align="left"
                style={{ paddingTop: 8, paddingBottom: 8, fontWeight: "bold" }}
              >
                Mode
              </Typography>
            </Grid>
          )}
          {value?.mode && (
            <CardCommon
              backgroundColor="table_header_background"
              style={{ margin: "0px 0px", height: 40 }}
            >
              <Grid container>
                {["Name", "Count", "Time"].map((label, index) => (
                  <Grid item xs={4} key={label} style={{ paddingTop: "8px" }}>
                    <Typography
                      align={
                        index === 0 ? "left" : index === 1 ? "center" : "right"
                      }
                      style={{
                        paddingLeft: index === 0 ? "12px" : "0px",
                        paddingRight: index === 2 ? "12px" : "0px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardCommon>
          )}
          <Grid container style={{ paddingBottom: "12px" }}>
            {Object.entries(value?.mode || {}).map(([key, val]: any) => (
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{ margin: "4px 0px", height: 40 }}
              >
                <Grid container key={key}>
                  {[key, val.count, val.totalTime].map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Typography
                        style={{
                          paddingTop: "8px",
                          paddingLeft: index === 0 ? "12px" : "0px",
                          paddingRight: index === 2 ? "12px" : "0px",
                        }}
                        align={
                          index === 0
                            ? "left"
                            : index === 1
                            ? "center"
                            : "right"
                        }
                      >
                        {index === 2 ? convertSeconds(item) : item}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardCommon>
            ))}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={2} style={{ paddingTop: "12px" }}>
          {value && (
            <CardCommon
              backgroundColor="table_header_background"
              style={{ margin: "0px 0px", height: 40, marginBottom: "0px" }}
            >
              <Grid container>
                {["Name", "Count", "Time"].map((label, index) => (
                  <Grid item xs={4} key={label} style={{ paddingTop: "0px" }}>
                    <Typography
                      align={
                        index === 0 ? "left" : index === 1 ? "center" : "right"
                      }
                      style={{
                        paddingLeft: index === 0 ? "12px" : "0px",
                        paddingRight: index === 2 ? "12px" : "0px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </CardCommon>
          )}
          <Grid container style={{ paddingBottom: "12px" }}>
            {Object.entries(value || {}).map(([key, val]: any) => (
              <CardCommon
                backgroundColor={"entity_highlight_background"}
                style={{ margin: "4px 0px", height: 40 }}
              >
                <Grid container key={key}>
                  {[key, val.count, val.totalTime].map((item, index) => (
                    <Grid item xs={4} key={index}>
                      <Typography
                        style={{
                          paddingTop: "8px",
                          paddingLeft: index === 0 ? "12px" : "0px",
                          paddingRight: index === 2 ? "12px" : "0px",
                        }}
                        align={
                          index === 0
                            ? "left"
                            : index === 1
                            ? "center"
                            : "right"
                        }
                      >
                        {index === 2 ? convertSeconds(item) : item}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </CardCommon>
            ))}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default BoxDetails;
