import React from "react";
import { Route, Switch } from "react-router-dom";
import Authorities from "../../../auth/authorities";
import { getIsAuthorized } from "../../../utils/authorities";
import MetricsListPage from "../pages/MetricsListPage";
import ListAltIcon from "@material-ui/icons/ListAlt";
import Location from "../../PrestoExpressLocationApp/pages/Location";
import DashboardIcon from "@material-ui/icons/Dashboard";

export const getSidebarRoutes = () => {
  const sidebarRoute = [
    {
      id: "Dashboard",
      title: "Dashboard",
      path: "",
      icon: <DashboardIcon />,
      isAuthorized: getIsAuthorized(Authorities.TASKS_READ),
    }

  ];
  return sidebarRoute;
};
export interface InsightAppRoutesProps {}

const InsightAppRoutes: React.FunctionComponent<InsightAppRoutesProps> = () => {
  return (
    <Switch>
      <Route path="/:locationId/presto-insight" component={MetricsListPage} exact />
      <Route path="/" component={Location} exact />
    </Switch>
  );
};

export default InsightAppRoutes;
