import { createStyles, makeStyles, useTheme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import _ from "lodash";
import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useRouteMatch } from "react-router-dom";
import {
  createLocationsInfo,
  fetchAllLocations,
} from "../../../services/locationApp/locationService";
import { handleChangeRestaurant } from "../../../utils";
import { getCurrentAppCode } from "../../../utils/getCurrentAppCode";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AddIcon from "@material-ui/icons/Add";
import queryString from "query-string";
import { getLocalStore } from "../../../utils/store/localStore";
import {
  ERROR_MESSAGE_CREATING_ERROR,
  ERROR_MESSAGE_UNEXPECTED_ERROR,
  URL_EATPRESTO,
  URL_PRESTO_CUSTOMER,
  URL_PRESTO_INSIGHT,
  URL_PRESTO_LOCATION,
  URL_PRESTO_MENUS,
  URL_PRESTO_TASK,
} from "../../../utils/consts";
import LocationCreateModal from "../LocationCreateModal";
import DefaultAlert from "../../alerts/DefaultAlert";
import { getCookie } from "../../../utils/cookies";
import { CustomTheme } from "../../../types/customTheme";

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({
    autoComplete: {
      "& .MuiAutocomplete-input": {
        color: theme.palette.custom.orange.contrastText,
      },
      "& .MuiInputBase-root": {
        color: "inherit",
      },
      [`& fieldset`]: {
        borderRadius: 10,
        border: `1px solid ${theme.palette.background.entity_border}`,
        color: theme.palette.custom.orange.contrastText,
      },
    },
  }),
);

export interface LocationSelectProps {
  handleChangeSidebar: any;
}

const locationDataInitial = {
  businessDisplayName: "",
  businessRegName: "",
  businessTradingName: "",
  locationIdentifier: "",
  contactNo: "",
  email: "",
  orderUrl: "",
  description: "",
  terms: "",
  web: "",
  lat: 0,
  lon: 0,
  addressFormatted: "",
  buildingNoOrName: "",
  addressLine1: "",
  addressLine2: "",
  city: "",
  county: "",
  country: "",
  postcode: "",
};

/* This component uses to select a location. */
const LocationSelect: React.FunctionComponent<LocationSelectProps> = ({
  handleChangeSidebar,
}) => {
  const [locationList, setLocationList] = useState<any>([]);
  const [error, setError] = useState("");
  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [restaurantInfo, setRestaurantInfo] = useState({});
  const [isOpenLocationCreateModal, setIsOpenLocationCreateModal] =
    useState(false);
  const [address, setAddress] = useState<any>({});
  const [locationData, setLocationData] = useState(locationDataInitial);
  const location = useLocation();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const appName = getCurrentAppCode(search);
  const match: any = useRouteMatch();

  const classes = useStyles();
  const idToken = getCookie("idToken");
  /* Get all location information using API call and response data set to locationList state. */
  const fetchLocationList = async () => {
    try {
      const res = await fetchAllLocations(idToken);
      let locationData = _.cloneDeep(res.data.data);
      // Add the 'Create New Location' object below all location details.
      locationData[res.data.data.length] = {
        id: "createNewLocation",
        businessDisplayName: "Create New Location",
      };
      setLocationList(locationData);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
    }
  };

  /* Update restaurant information after change location */
  React.useEffect(() => {
    const setSelectedLocation = () => {
      const [__, locationId, _] = location.pathname.split("/");
      const selectedLocation = locationList.find(
        (restaurant: any) => restaurant.id === locationId,
      );
      if (selectedLocation) {
        setRestaurantInfo(selectedLocation);
        // Checking if the user is authorized.
        const rolesAuthoritiesString = getLocalStore("authorities");
        // If use is authorized, update the local storage using user daa.
        if (!rolesAuthoritiesString) {
          // Update the location details in the local storage.
          handleChangeRestaurant(selectedLocation);
          handleChangeSidebar(selectedLocation);
        }
      }
    };

    setSelectedLocation();
  }, [dispatch, location.pathname, locationList, setRestaurantInfo]);

  const token = queryString.parse(window.location.search).t;
  /* Get the location data in the initial load. */
  React.useEffect(() => {
    if(!token) {
      fetchLocationList();
    }
  }, []);

  /* Navigate the selected location. */
  const handleLocationChange = (e: any, locationData: any) => {
    if (!locationData?.id) return;
    const [__, ___, ...routePath] = location.pathname.split("/");
    // If the user selects the 'Create New Location' option, the add new location modal will open.
    if (locationData.id === "createNewLocation") {
      setIsOpenLocationCreateModal(true);
    } else {
      // Otherwise, redirect to the selected location.
      // Store location data in the local storage.
      handleChangeRestaurant(locationData);
      if (
        window.location.href.includes("/presto-menus-info")
      ) {
        if (match.params.locationId === locationData.id) {
          window.location.href = `/${locationData.id}/${routePath.join("/")}`;
        } else {
          window.location.href = `/${locationData.id}/presto-menus/menu`;
        }
      } else {
        window.location.href = `/${locationData.id}/${routePath.join("/")}`;
      }
    }
  };

  /* Update the location data after selecting the location using google Maps. */
  const handleChangeLocationData = (e: any) => {
    const { name, value } = e.target;
    setLocationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  /* Update the business information data, contact information data, and location data */
  const getNodeDataByState = () => {
    const updatedData = _.cloneDeep(locationDataInitial);
    updatedData.lat = address.lat;
    updatedData.lon = address.lon;
    updatedData.addressFormatted = address.addressFormatted;
    updatedData.buildingNoOrName = address.buildingNoOrName;
    updatedData.addressLine1 = address.addressLine1;
    updatedData.addressLine2 = address.addressLine2;
    updatedData.city = address.city;
    updatedData.county = address.county;
    updatedData.country = address.country;
    updatedData.postcode = address.postcode;
    updatedData.businessDisplayName = locationData.businessDisplayName;
    updatedData.businessRegName = locationData.businessRegName;
    updatedData.businessTradingName = locationData.businessTradingName;
    updatedData.locationIdentifier = locationData.locationIdentifier;
    updatedData.description = locationData.description;
    updatedData.contactNo = locationData.contactNo;
    updatedData.email = locationData.email;
    updatedData.web = locationData.web;
    updatedData.orderUrl = locationData.orderUrl;
    updatedData.terms = locationData.terms;
    return updatedData;
  };

  /* After creating the location, redirect to the dashboard page related to that location. */
  const handleLocation = (location: any) => {
    // If the current app is 'sale', Redirect to the sale app dashboard page.
    if (
      window.location.href.includes(
        `${process.env.REACT_APP_PRESTO_SALES_DOMAIN}`,
      )
    ) {
      window.location.href = `/${location.id}/dashboard`;
    } else if (
      // If the current app is 'eatpresto', Redirect to the sale app eatpresto page.
      window.location.href.includes(
        `${process.env.REACT_APP_PRESTO_EATPRESTO_DOMAIN}`,
      )
    ) {
      window.location.href = `/${location.id}/${URL_EATPRESTO}`;
    } else if (
      // If the current app is 'location', Redirect to the sale app location page.
      window.location.href.includes(
        `${process.env.REACT_APP_PRESTO_LOCATION_DOMAIN}`,
      )
    ) {
      window.location.href = `/${location.id}/${URL_PRESTO_LOCATION}`;
    } else if (
      // If the current app is 'task', Redirect to the sale app task page.
      window.location.href.includes(
        `${process.env.REACT_APP_PRESTO_TASK_DOMAIN}`,
      )
    ) {
      window.location.href = `/${location.id}/${URL_PRESTO_TASK}`;
    } else if (
      // If the current app is 'menu', Redirect to the sale app menu page.
      window.location.href.includes(
        `${process.env.REACT_APP_PRESTO_MENU_DOMAIN}`,
      )
    ) {
      window.location.href = `/${location.id}/${URL_PRESTO_MENUS}`;
    } else if (
      // If the current app is 'menu', Redirect to the sale app menu page.
      window.location.href.includes(`${process.env.URL_PRESTO_CUSTOMER}`)
    ) {
      window.location.href = `/${location.id}/${URL_PRESTO_CUSTOMER}/presto-customer/customerList`;
    } else if (
      // If the current app is 'menu', Redirect to the sale app menu page.
      window.location.href.includes(`${process.env.URL_PRESTO_CUSTOMER}`)
    ) {
      window.location.href = `/${location.id}/${URL_PRESTO_INSIGHT}`;
    }
  };

  /* Sending the location details to the backend. */
  const handleCreateLocation = async () => {
    setError("");
    const createData = getNodeDataByState();
    try {
      const res = await createLocationsInfo(createData);
      const [__, ___, ...routePath] = location.pathname.split("/");
      handleLocation(res.data.data);
    } catch (error) {
      setError(ERROR_MESSAGE_CREATING_ERROR);
    }
  };

  const theme = useTheme();

  return (
    <div
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <Autocomplete
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        size={"small"}
        id="locationSelectGlobal"
        color="inherit"
        options={locationList}
        getOptionLabel={(option: any) => option.businessDisplayName || "NA"}
        style={{ width: 300 }}
        value={restaurantInfo}
        onChange={handleLocationChange}
        hidden={!_.isEmpty(appName)}
        classes={{ root: classes.autoComplete }}
        renderOption={(option: any) =>
          option.id !== "createNewLocation" ? (
            <>
              <StorefrontIcon style={{ marginRight: "8px" }} />
              {option.businessDisplayName}
            </>
          ) : (
            <>
              <AddIcon style={{ marginRight: "8px" }} />
              {option.businessDisplayName}
            </>
          )
        }
        popupIcon={
          <ArrowDropDownIcon color={hover || open ? "inherit" : "primary"} />
        }
        renderInput={(params: any) => (
          <TextField
            color="inherit"
            {...params}
            label=""
            placeholder="Select location"
            variant="outlined"
          />
        )}
      />
      <LocationCreateModal
        handleCreateLocation={handleCreateLocation}
        open={isOpenLocationCreateModal}
        setOpen={setIsOpenLocationCreateModal}
        address={address}
        setAddress={setAddress}
        handleChangeLocationData={handleChangeLocationData}
        locationData={locationData}
        setLocationData={setLocationData}
      />
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </div>
  );
};

export default LocationSelect;
