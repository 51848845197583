import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";
import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import "jspdf-autotable";

import { CustomTheme } from "../../../../types/customTheme";
import WithLoading from "../../../../utils/WithLoading";
import BoxDetails from "./BoxDetails";
import SpecificBox from "./SpecificBox";

export interface CustomerNodeProps {
  isLoading: any;
  metricsNode: any;
}

const useStyles = makeStyles((theme: CustomTheme) => createStyles({}));

const MetricsNode: React.FunctionComponent<CustomerNodeProps> = ({
  isLoading,
  metricsNode,
}) => {
  console.log("sdfsdfsdfdgdfg", metricsNode);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SpecificBox
            keyProps={"loadAverageTime"}
            value={metricsNode["loadAverageTime"]}
          />
        </Grid>

        <Grid item xs={6}>
          <SpecificBox
            keyProps={"preparationAverageTime"}
            value={metricsNode["preparationAverageTime"]}
          />
        </Grid>
        <Grid item xs={6}>
          <BoxDetails
            keyProps={"deliveryAverageTime"}
            value={metricsNode["deliveryAverageTime"]}
          />
        </Grid>
        <Grid item xs={6}>
          <BoxDetails
            keyProps={"stationMetrics"}
            value={metricsNode["stationMetrics"]}
          />
        </Grid>
        <Grid item xs={6}>
          <BoxDetails
            keyProps={"locationAverageTime"}
            value={metricsNode["locationAverageTime"]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default WithLoading(MetricsNode);
