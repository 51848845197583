import { axiosClient } from "../client";

const METRICS_INFO_GET_ALL = (locationId) =>
  `${process.env.REACT_APP_API_PRESTO_EXPRESS_INSIGHT}/location/${locationId}/getAllMetrics`;

export const fetchAllMetricsInfo = (locationId) =>
  axiosClient({
    method: "GET",
    url: METRICS_INFO_GET_ALL(locationId),
    headers: {
      "X-Role-Location": locationId,
      "Content-Type": "application/json",
    },
  });
