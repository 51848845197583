import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useRouteMatch } from "react-router-dom";

import { getCookie } from "../../../../utils/cookies";
import { ERROR_MESSAGE_UNEXPECTED_ERROR } from "../../../../utils/consts";
import {
  fetchBannerInfo,
  fetchLoyaltyProgramInfo,
} from "../../../../services/customerApp/customerDetails";
import DefaultAlert from "../../../../components/alerts/DefaultAlert";
import Authorities from "../../../../auth/authorities";
import withAuthority from "../../../../components/Auth/withAuthority";
import { Box, Hidden } from "@material-ui/core";
import PageHeaderMobile from "../../../../components/common/PageHeader/PageHeaderMobile";
import AlertDialogDefault from "../../../../components/alerts/AlertDialogDefault";
import { fetchAllMetricsInfo } from "../../../../services/insightApp/metrics";
import MetricsNode from "./MetricsNode";

/**
 * MetricsListPage Component
 *
 * This component is responsible for rendering a paginated list of customer data.
 * It fetches customer information from an API and allows users to navigate through pages and sort the data.
 */
const MetricsListPage: React.FunctionComponent<{}> = () => {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [metricsNode, setMetricsNode] = useState<any>({});

  const match: any = useRouteMatch();

  const handleGetMetricsInformation = async () => {
    try {
      const res = await fetchAllMetricsInfo(match.params.locationId);
      setMetricsNode(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setError(ERROR_MESSAGE_UNEXPECTED_ERROR);
      setIsLoading(false);
    }
  };

  /**
   * useEffect Hook
   *
   * This hook is used to perform side effects in the component.
   * In this case, it sets the document title, fetches customer data, and fetches location information.
   */
  useEffect(() => {
    // Set the document title
    document.title = " Customers & Loyalty - Loyalty Banners";

    // Fetch location information
    handleGetMetricsInformation();
  }, []);

  return (
    <>
      <Hidden lgUp>
        <PageHeaderMobile />
      </Hidden>
      <Box style={{ marginTop: "18px" }}>
        <MetricsNode isLoading={isLoading} metricsNode={metricsNode} />
      </Box>
      <DefaultAlert
        open={!!error}
        handleClose={() => setError("")}
        message={error}
        severity="error"
      />
    </>
  );
};

export default withAuthority(MetricsListPage, Authorities.CUSTOMER_LIST_READ);
